
export const ADDTOCART = "add-to-cart-manage";
export const POSTSList = "post-list";
export const PRODUCTDETAIL = "product-details";
export const PRODUCTList = "product-list";
export const PRODUCTDEPENDENTCATEGORY = "product-dependent-list";
export const PRODUCTCATEGORY = "product-category-list"
export const PRODUCTSEARCH = "search";
export const SOCIALLOGIN = "social-login";
export const WISHLIST = "product-like-wishlist";

