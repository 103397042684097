import * as React from 'react';
import * as XLSX from 'xlsx';
import Layout from './layout/Layout';
import Button from "react-bootstrap/Button";
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import api from '../../../helper/api';
import { getServerURL } from '../../../helper/envConfig';
import Avatar from '@mui/material/Avatar';

// Function to get the current date in YYYY-MM-DD format
const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const columns = [
  { field: 'transaction_id', headerName: 'Transaction ID', width: 300 },
  { field: 'order_display_id', headerName: 'Order ID', width: 300 },
  { field: 'tracking_number', headerName: 'Tracking Number', width: 300 },
  { field: 'product_image', headerName: 'Product Image', width: 150,renderCell : ((pararms)=>{
    return <Avatar src={pararms.value} variant="rounded" />
  }) },
  { field: 'product_name', headerName: 'Product Name', width: 300 },
  { field: 'fullname', headerName: 'Full name', width: 130 },
  { field: 'address', headerName: 'Address', width: 130 },
  { field: 'city', headerName: 'City', width: 130 },
  { field: 'state_name', headerName: 'State', width: 130 },
  { field: 'country_name', headerName: 'Country', width: 130 },
  { field: 'zipcode', headerName: 'Zipcode', width: 130 },
  { field: 'contact_no', headerName: 'Contact No', width: 130 },
  {
    field: 'isPayed',
    headerName: 'Paid',
    width: 160,
  },
  {
    field: 'grand_total',
    headerName: 'Grand Total',
    width: 150,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 180,
  },

];

const serverURL = getServerURL();

export default function DataTable() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const handleExport = async () => {
    try {
      setLoading(true);
      const response = await api.postWithToken(`${serverURL}user/orders?page=${1}&limit=${rowCount}`);
      if (response.data.status) {
        // Map the response data to match the columns
        const mappedRows = response.data.orders.map((order) => ({
          id: order._id,
          transaction_id : order.transaction_id,
          order_id : order.order_display_id,
          product_name : order.product_name,
          product_image : `${order?.imageUrl}${order?.product_id}/${order.product_images[0]?.file_name}` || '',
          fullname: order.shipping_address_object.fullname,
          address: order.shipping_address_object.address,
          city: order.shipping_address_object.city,
          state_name: order.state_name,
          country_name: order.country_name,
          zipcode: order.shipping_address_object.zipcode,
          contact_no: order.shipping_address_object.contact_no,
          sub_total_price: order.sub_total_price,
          tracking_number: order.tracking_number,
          payment_method: order?.transaction_object?.charges?.data[0]?.payment_method_details?.card?.brand || 'null',
          isPayed: order?.transaction_object?.charges?.data[0]?.paid ? 'Yes' : 'No',
          grand_total: `USD ${order.grand_total}`,
          created_at: order.created_at,
          
        }));
        // Convert JSON to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(mappedRows);

        // Create a new workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');

        // Generate a unique filename with the current date
        const filename = `Orders_${getCurrentDate()}.xlsx`;

        // Download the Excel file
        XLSX.writeFile(workbook, filename);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  const fetchOrders = async (page,limit) => {
    try {
      setLoading(true);
      const response = await api.postWithToken(`${serverURL}user/orders?page=${page+1}&limit=${limit}`);
      if (response.data.status) {
        // Map the response data to match the columns
        const mappedRows = response.data.orders.map((order) => ({
          id: order._id,
          transaction_id : order.transaction_id,
          order_display_id : order.order_display_id,
          product_name : order.product_name,
          product_image : `${order?.imageUrl}${order?.product_id}/${order.product_images[0]?.file_name}` || '',
          fullname: order.shipping_address_object.fullname,
          address: order.shipping_address_object.address,
          city: order.shipping_address_object.city,
          state_name: order.state_name,
          country_name: order.country_name,
          zipcode: order.shipping_address_object.zipcode,
          contact_no: order.shipping_address_object.contact_no,
          sub_total_price: order.sub_total_price,
          tracking_number: order.tracking_number,
          payment_method: order?.transaction_object?.charges?.data[0]?.payment_method_details?.card?.brand || 'null',
          isPayed: order?.transaction_object?.charges?.data[0]?.paid ? 'Yes' : 'No',
          grand_total: `USD ${order.grand_total}`,
          created_at: order.created_at,
          
        }));
        setRowCount(response.data.totalOrders)
        setRows(mappedRows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchOrders(paginationModel.page,paginationModel.pageSize);
  }, [paginationModel]);

  return (
    <Layout>
      <div className='d-flex justify-content-between mb-2'>
      <Typography variant="h4">
        User Orders
    </Typography>
      <Button className='btn btn-dark' onClick={handleExport} disabled={isLoading}>Export To Excel</Button>
      </div>

      <Paper sx={{ width: '100%' }}>
        <DataGrid
          loading={isLoading}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sx={{ border: 0 }}
        />
      </Paper>
    </Layout>
  );
}
