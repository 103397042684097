import * as React from 'react';
import * as XLSX from 'xlsx';
import Layout from './layout/Layout';
import Button from "react-bootstrap/Button";
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import api from '../../../helper/api';
import { getServerURL } from '../../../helper/envConfig';
import Avatar from '@mui/material/Avatar';

// Function to get the current date in YYYY-MM-DD format
const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const columns = [
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'contact_no', headerName: 'Contact No', width: 130 },
    { 
      field: 'profile_image', 
      headerName: 'Profile Image', 
      width: 150,
      renderCell: (params) => (
        <Avatar src={params.value} variant="rounded" />
      )
    },
    { field: 'gender', headerName: 'Gender', width: 100 },
    { field: 'total_following', headerName: 'Following', width: 130 },
    { field: 'total_followers', headerName: 'Followers', width: 130 },
    { field: 'total_likes', headerName: 'Likes', width: 130 },
    { field: 'city', headerName: 'City', width: 130 },
    { field: 'state_name', headerName: 'State', width: 130 },
    { field: 'country_name', headerName: 'Country', width: 130 },
    { field: 'zipcode', headerName: 'Zipcode', width: 130 },
    { field: 'address', headerName: 'Address', width: 200 },
    {
      field: 'is_active',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => (
        <span style={{ color: params.value === 'Active' ? 'green' : 'red' }}>
          {params.value}
        </span>
      )
    },
  ];

const serverURL = getServerURL();

export default function DataTable() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const handleExport = async () => {
    try {
      setLoading(true);
      const response = await api.postWithToken(`${serverURL}users?page=${1}&limit=${rowCount}`);
      if (response.data.status) {
        // Map the response data to match the columns
        const mappedRows = response.data.users.map((user) => ({
            id: user._id,
            username: user.username,
            email: user.email,
            contact_no: user.contact_no || "N/A",
            profile_image: user.profile_image ? `${user.imageUrl}${user?._id}/${user.profile_image}` : '', // Construct full image URL if profile_image is present
            gender: user.gender,
            total_following: user.total_following,
            total_followers: user.total_followers,
            total_likes: user.total_likes,
            city: user.city || "N/A",
            zipcode: user.zipcode || "N/A",
            address: user.address || "N/A",
            is_active: user.is_active ? "Active" : "Inactive",
            state_name: user.state_name,
            country_name: user.country_name,
        }));
        // Convert JSON to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(mappedRows);

        // Create a new workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

        // Generate a unique filename with the current date
        const filename = `Users_${getCurrentDate()}.xlsx`;

        // Download the Excel file
        XLSX.writeFile(workbook, filename);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  const fetchUsers = async (page,limit) => {
    try {
      setLoading(true);
      const response = await api.postWithToken(`${serverURL}users?page=${page+1}&limit=${limit}`);
      if (response.data.status) {
        // Map the response data to match the columns
        const mappedRows = response.data.users.map((user) => ({
          id: user._id,
          username: user.username,
          email: user.email,
          contact_no: user.contact_no || "N/A",
          profile_image: user.profile_image ? `${user.imageUrl}${user.profile_image}` : '', // Construct full image URL if profile_image is present
          gender: user.gender,
          total_following: user.total_following,
          total_followers: user.total_followers,
          total_likes: user.total_likes,
          city: user.city || "N/A",
          zipcode: user.zipcode || "N/A",
          address: user.address || "N/A",
          is_active: user.is_active ? "Active" : "Inactive",
          state_name: user.state_name,
          country_name: user.country_name,
        }));
  
        setRowCount(response.data.totalUsers); // Ensure it matches the correct total field
        setRows(mappedRows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchUsers(paginationModel.page,paginationModel.pageSize);
  }, [paginationModel]);

  return (
    <Layout>
      <div className='d-flex justify-content-between mb-2'>
      <Typography variant="h4">
        User
    </Typography>
      <Button className='btn btn-dark' onClick={handleExport} disabled={isLoading}>Export To Excel</Button>
      </div>

      <Paper sx={{ width: '100%' }}>
        <DataGrid
          loading={isLoading}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sx={{ border: 0 }}
        />
      </Paper>
    </Layout>
  );
}
